import React, { Component } from 'react';
// import Meta from './Meta';
import Layout from './Layout';
import GlobalStyle from './GlobalStyle';
import { Provider as URQLProvider, createClient } from 'urql';

import { API_URL } from 'config';

const client = createClient({
    url: API_URL
});
class Page extends Component {
    render() {
        return (
            <URQLProvider value={client}>
                {/* <CSSReset /> */}
                <GlobalStyle />
                {/* <Meta /> */}
                <Layout>{this.props.children}</Layout>
            </URQLProvider>
        );
    }
}

export default Page;
