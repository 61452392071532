export const API_URL =
    process.env.REACT_APP_STAGE === 'production'
        // ? 'https://spexware-api.herokuapp.com/graphql'
        ? 'https://spexware-api.herokuapp.com/graphql'
        : 'http://localhost:7777/graphql';

export const ALGOLIA_APP_ID = 'BIAVTJREDV'
export const ALGOLIA_SEARCH_KEY = '0de0b79780f71db0a0c8888cb8d4a212'
export const CLOUDINARY_CLOUD_PRESET = 'spexware_medical_records'
export const CLOUDINARY_UPLOAD_URL = 'https://api.cloudinary.com/v1_1/ashiknesin/auto/upload'
export default {
    API_URL,
    ALGOLIA_APP_ID,
    ALGOLIA_SEARCH_KEY,
    CLOUDINARY_CLOUD_PRESET,
    CLOUDINARY_UPLOAD_URL
};
