import React, { Component } from 'react';
import PropTypes from 'prop-types';
import algoliasearch from 'algoliasearch/lite';
import {
    InstantSearch,
    Configure,
    Hits,
    Highlight,
    connectSearchBox,
} from 'react-instantsearch-dom';
import { withRouter } from "react-router-dom";

import Autocomplete from './Autocomplete';
import {
    ALGOLIA_APP_ID,
    ALGOLIA_SEARCH_KEY
} from 'config'

const searchClient = algoliasearch(
    ALGOLIA_APP_ID,
    ALGOLIA_SEARCH_KEY
);


class App extends Component {
    state = {
        query: '',
    };

    onSuggestionSelected = (_, { suggestion }) => {

        if (suggestion._id) {
            this.props.history.push(`/customers/${suggestion._id}`)
        }

        // console.log()
        // this.setState({
        //     query: suggestion.name,
        // });
    };

    onSuggestionCleared = () => {
        this.setState({
            query: '',
        });
    };

    render() {
        const { query } = this.state;

        return (
            <div className="container">
                {/* TODO: Add tailwind search ui */}
                {/* <input id="search" name="search" className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Search" type="search" /> */}

                <InstantSearch indexName="prod_spexware_customers" searchClient={searchClient}>
                    <Configure hitsPerPage={5} />
                    <Autocomplete
                        onSuggestionSelected={this.onSuggestionSelected}
                        onSuggestionCleared={this.onSuggestionCleared}
                    />
                </InstantSearch>

            </div>
        );
    }
}

function Hit(props) {
    return (
        <div>
            <Highlight attribute="name" hit={props.hit} />
        </div>
    );
}

Hit.propTypes = {
    hit: PropTypes.object.isRequired,
};

export default withRouter(App);
