import React from 'react'
import Page from "./components/Page";
// import Home from "./pages/index";
import { useAuth } from 'context/auth-context'
const AuthenticatedApp = React.lazy(() => import(/* webpackPrefetch: true */ './authenticated-app'),
)
const UnauthenticatedApp = React.lazy(() => import('./unauthenticated-app'))

function App() {
  const { user } = useAuth()
  return (
    <React.Suspense fallback={<p>Loading..</p>}>
      <Page>
        {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
      </Page>
    </React.Suspense>
  );
}

export default (App);
