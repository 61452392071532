import axios from 'axios'
import { API_URL } from 'config'
export const localStorageKey = '__spexware_token__'

const getConfig = () => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            // withCredentials: true
        }
    }

    if (window.localStorage[localStorageKey]) {
        config.headers['Authorization'] = `Bearer ${window.localStorage[localStorageKey]}`
    }
    return config
}

const ME_QUERY = `query {
  me{
    email,
    permissions
  }
}`
export const getUser = async () => {
    const payload = {
        query: ME_QUERY
    }

    const { data: { data } } = await axios.post(API_URL, payload, getConfig())
    if (data?.me) {
        return data.me
    }
    return null;
}


// Login



export const login = async (formData) => {

    const LOGIN_MUTATION = `
  mutation(
    $email: String!
    $password: String!
  ) {
    signin(
        email: $email
        password: $password
    ) {
      authToken
    }
  }
`;
    const payload = {
        query: LOGIN_MUTATION,
        variables: formData
    }

    const { data: { data } } = await axios.post(API_URL, payload, getConfig())

    if (data?.signin?.authToken) {
        window.localStorage[localStorageKey] = data.signin.authToken
    }

    return data
}

// Logout
export const logout = () => {
    delete window.localStorage[localStorageKey]
    window.location.reload() // TODO: Handle it without refresh
}