import React from "react"
import Navigation from "./Navigation"
import { Toaster } from 'react-hot-toast';

function Layout({ children }) {
  return (
    <div className="bg-gray-100">
      <div className="min-h-screen bg-white">
        <Navigation />
        <div className="py-10">
          <main>
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
              {children}
            </div>
          </main>
        </div>
      </div>
      <Toaster position="bottom-left" reverseOrder={false} />
    </div>

  )
}

export default Layout