import React from 'react'
import { getUser, login, logout, localStorageKey } from 'utils/auth-client'




const AuthContext = React.createContext()

function AuthProvider({ children }) {
    const initialState = {
        status: 'pending',
        error: null,
        user: null,
    }
    const [state, setState] = React.useState(initialState)
    const validateUser = () => {

        getUser().then(
            user => setState({ status: 'success', error: null, user }),
            error => setState({ status: 'error', error, user: null }),
        )

    }
    React.useEffect(() => {
        validateUser()
    }, [])

    const handleLogin = async (formData) => {
        try {
            const result = await login(formData)
            validateUser();
            return result;

        } catch (error) {
            const errorMsg = error.response.data.errors[0].message
            return {
                error: {
                    message: errorMsg
                }
            }
        }

    }


    const handleLogout = () => {
        logout()
    }



    return (
        <AuthContext.Provider value={{ state, validateUser, login: handleLogin, logout: handleLogout }}>
            {state.status === 'pending' ? (
                'Loading...'
            ) : state.status === 'error' ? (
                <div>
                    Oh no
                    <div>
                        <pre>{state.error.message}</pre>
                    </div>
                </div>
            ) : (
                        children
                    )}
        </AuthContext.Provider>
    )
}

function useAuth() {
    const context = React.useContext(AuthContext)
    const { state, validateUser, login } = context;
    const isPending = state.status === 'pending'
    const isError = state.status === 'error'
    const isSuccess = state.status === 'success'
    const isAuthenticated = state.user && isSuccess


    return {
        ...state,
        isPending,
        isError,
        isSuccess,
        isAuthenticated,
        validateUser,
        login,
        logout
    }
}

export { useAuth, AuthProvider };