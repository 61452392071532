import React from "react"
import { Global, css } from "@emotion/core"

function GlobalStyle() {
  return (
    <Global
      styles={css`

      `}
    />
  )
}

export default GlobalStyle